import {useNavigate} from 'react-router-dom';
import {
    Body,
    Breadcrumbs,
    DataTable,
    DateDisplay,
    type Pageable,
    Title,
    useFlag,
    usePagination
} from '@mlyngvo/common-ui';
import {
    Button,
    Divider,
    Dropdown, FormControl, FormLabel,
    IconButton, List, ListDivider, ListItemButton, ListItemContent,
    ListItemDecorator,
    Menu,
    MenuButton,
    MenuItem, Option, Select,
    Typography
} from '@mui/joy';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import React, {useEffect, useState} from 'react';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {ConfirmDialog} from '../../component/confirm-dialog';
import {toDateString, handleExplicitClick, notBlank} from '../../utils';
import {NotificationFilterStatus, type NotificationListView, useNotificationApi} from '../../data/notification';
import {useLocalization, useTableI18n} from '../../context/localization';
import {ClubSelect} from '../../component/club-select';
import {NotificationPublishedChip} from './notification-published-chip';
import {ClubLabel} from '../../component/club-label';
import {NotificationCreator} from './creator';
import {AuthWrapper, useAuthContext} from '../../context/auth';
import {UserRole} from '../../data/user';
import {RouterLink} from '../../component/link';

const StorageKey = 'page-notifications';

export function NotificationList() {
    return (
        <AuthWrapper>
            <ContentView />
        </AuthWrapper>
    );
}

function ContentView() {
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {list} = useNotificationApi();
    const {role, clubId} = useAuthContext();

    const [creatorMode, setCreatorMode, clearCreatorMode] = useFlag(false);

    const pagination = usePagination(({
        paginationKey: `${StorageKey}:${role}`,
        fetch: async (pageable: Pageable<NotificationListView>) => {
            const p = pageable;
            if (clubId !== undefined) {
                p.filter = {
                    ...p.filter,
                    clubId
                };
            }
            return await list(p);
        }
    }));

    async function handleDelete() {
        try {
            await pagination.onReload();
        } catch (error) {
            console.error('Failed to handle delete', error);
        }
    }

    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('notifications.title') }
                    ]}
                />
            )}
            title={(
                <Title
                    title={t('notifications.title')}
                    actions={(
                        <Button
                            color="primary"
                            startDecorator={<AddRoundedIcon />}
                            size="sm"
                            onClick={setCreatorMode}
                            disabled={role === UserRole.User}
                        >
                            {t('notifications.addNew')}
                        </Button>
                    )}
                />
            )}
        >
            <DataTable
                {...pagination}
                stickyLastColumn
                i18n={{
                    ...useTableI18n(),
                    searchLabel: t('notifications.search')
                }}
                headers={[
                    { label: t('common.title'), sortKey: ['title'], width: 140 },
                    { label: t('common.createdAt'), sortKey: ['createdAt'], width: 80 },
                    { label: t('common.publishedAt'), sortKey: ['publishedAt'], width: 80 },
                    { label: t('clubs.singular'), width: 180 },
                    { label: '', width: 60 },
                ]}
                renderTableRow={notification => <TableRow key={notification.id} {...{ notification }} onDelete={handleDelete} />}
                renderListRow={notification => <ListRow key={notification.id}  {...{ notification }} />}
                renderFilter={(onFilter, filter) => <Filter {...{ onFilter, filter }} />}
            />

            <NotificationCreator
                open={creatorMode}
                onCancel={clearCreatorMode}
            />
        </Body>
    );
}

function TableRow({notification: {id, title, createdAt, publishedAt, clubName, clubId}, onDelete}: { notification: NotificationListView, onDelete: () => Promise<void> }) {
    const {t, language} = useLocalization();
    const {deleteNotification} = useNotificationApi();

    const [deletionPromptOpen, setDeletionPromptOpen, clearDeletionPromptOpen] = useFlag(false);

    function handleDelete() {
        deleteNotification(id)
            .then(async () => {
                await onDelete();
                setDeletionPromptOpen();
            })
            .catch(console.error);
    }

    return (
        <>
            <tr>
                <td>
                    <Typography
                        component={RouterLink}
                        href={`/notifications/${id}`}
                        level="body-xs"
                    >
                        <strong>{title}</strong>
                    </Typography>
                </td>
                <td>
                    <Typography level="body-xs">
                        <DateDisplay value={createdAt} locale={language} />
                    </Typography>
                </td>
                <td>
                    {notBlank(publishedAt) && (
                        <Typography level="body-xs">
                            <DateDisplay value={publishedAt as string} locale={language} />
                        </Typography>
                    )}
                </td>
                <td>
                    <ClubLabel label={clubName} clubId={clubId} noWrap />
                </td>
                <td onClick={event_ => {
                    event_.stopPropagation();
                }}>
                    <ItemMenu
                        {...{
                            id,
                            onDelete: setDeletionPromptOpen,
                        }}
                    />
                </td>
            </tr>
            <ConfirmDialog
                open={deletionPromptOpen}
                onCancel={clearDeletionPromptOpen}
                onConfirm={handleDelete}
                title={t('accountGroups.deleteTitle')}
                message={t('accountGroups.deletePrompt')}
                color="danger"
            />
        </>
    );
}

function ListRow({notification: {id, title, createdAt, publishedAt, clubName, clubId}}: { notification: NotificationListView }) {
    const navigate = useNavigate();
    const {t, language} = useLocalization();

    return (
        <List
            size="sm"
            sx={{
                '--ListItem-paddingX': 0,
            }}
        >
            <ListItemButton
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    '&:hover': {
                        background: 'none !important'
                    }
                }}
                onClick={() => {
                    navigate(`/notications/${id}`);
                }}
            >
                <ListItemContent>
                    <Typography fontWeight={600} gutterBottom sx={{ mt: 0.5 }}>{title}</Typography>
                    <ClubLabel label={clubName} clubId={clubId} />
                    <Typography level="body-xs">
                        {t('common.createdAt')}: <DateDisplay value={createdAt} locale={language} />
                    </Typography>
                    {notBlank(publishedAt) && (
                        <Typography level="body-xs">
                            {t('common.publishedAt')}: <DateDisplay value={publishedAt as string} locale={language} />
                        </Typography>
                    )}
                </ListItemContent>
                <NotificationPublishedChip {...{ publishedAt }} />
            </ListItemButton>
            <ListDivider />
        </List>
    );
}

function Filter({onFilter, filter = {}}: { onFilter: (filter: Record<string, any>) => void, filter: Record<string, any>|undefined }) {
    const {t} = useLocalization();
    const {role} = useAuthContext();

    const [status, setStatus] = useState('');

    useEffect(() =>
        {
            if (typeof filter.status === 'string'
                && filter.status !== status
            ) {
                setStatus(filter.status);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filter]);

    function handleStatus(s: string) {
        setStatus(s);
        onFilter({
            ...filter,
            status: s
        });
    }

    function handleClubId(clubId?: string) {
        onFilter({
            ...filter,
            clubId
        });
    }

    return (
        <>
            <FormControl size="sm">
                <FormLabel>{t('common.status')}</FormLabel>
                <Select
                    size="sm"
                    slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                    value={status}
                    onChange={(_, newValue) => { handleStatus(newValue ?? ''); }}
                >
                    <Option value="">{t('common.all')}</Option>
                    <Option value={NotificationFilterStatus.Pending}>{t('common.pending')}</Option>
                    <Option value={NotificationFilterStatus.Published}>{t('common.published')}</Option>
                </Select>
            </FormControl>
            {role === UserRole.Root && (
                <ClubSelect
                    small
                    defaultClubId={filter.clubId}
                    onChange={handleClubId}
                />
            )}
        </>
    );
}

interface ItemMenuProperties {
    id: string;
    onDelete: () => void;
}

function ItemMenu({id, onDelete}: ItemMenuProperties) {
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role} = useAuthContext();

    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <Dropdown open={menuOpen} onOpenChange={(event_, open) => { setMenuOpen(open); }}>
            <MenuButton
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
            >
                <MoreHorizRoundedIcon />
            </MenuButton>
            <Menu size="sm" sx={{ minWidth: 140 }} >
                <MenuItem
                    onClick={event_ =>
                    { handleExplicitClick(event_, () => { navigate(`/notifications/${id}`); }); }
                    }
                >
                    <ListItemDecorator>
                        <VisibilityRoundedIcon />
                    </ListItemDecorator>
                    {t('actions.view')}
                </MenuItem>
                <MenuItem
                    onClick={event_ =>
                    { handleExplicitClick(event_, () => { navigate(`/notifications/${id}/edit`); }); }
                    }
                    disabled={role === UserRole.User}
                >
                    <ListItemDecorator>
                        <EditRoundedIcon />
                    </ListItemDecorator>
                    {t('actions.edit')}
                </MenuItem>
                <Divider />
                <MenuItem
                    color="danger"
                    onClick={event_ =>
                    { handleExplicitClick(event_, () => { onDelete(); }); }
                    }
                    disabled={role === UserRole.User}
                >
                    <ListItemDecorator>
                        <DeleteRoundedIcon />
                    </ListItemDecorator>
                    {t('actions.delete')}
                </MenuItem>
            </Menu>
        </Dropdown>
    );
}