import {
    Body,
    Breadcrumbs,
    DataTable,
    DateTimeDisplay,
    type Pageable,
    Title,
    useFlag,
    usePagination
} from '@mlyngvo/common-ui';
import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {
    Avatar, Button,
    Dropdown,
    FormControl, FormLabel, IconButton,
    List,
    ListDivider,
    ListItemButton,
    ListItemContent,
    ListItemDecorator, Menu, MenuButton, MenuItem, Option, Select,
    Typography
} from '@mui/joy';
import EuroRoundedIcon from '@mui/icons-material/EuroRounded';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import {useLocalization, useTableI18n} from '../../context/localization';
import {displayAmount, toDatetimeString, handleExplicitClick, notBlank} from '../../utils';
import {useWalletApi, type Wallet, WalletFilterState, WalletType} from '../../data/wallet';
import {ClubSelect} from '../../component/club-select';
import {ClubLabel} from '../../component/club-label';
import {AuthWrapper, useAuthContext} from '../../context/auth';
import {UserRole} from '../../data/user';
import {RouterLink} from '../../component/link';
import {WalletExportModal} from './wallet-export-modal';

const StorageKey = 'page-club-wallets';

export function ClubWalletList() {
    return (
        <AuthWrapper>
            <ContentView />
        </AuthWrapper>
    );
}

function ContentView() {
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role, clubId} = useAuthContext();
    const {list} = useWalletApi();

    const [exportMode, setExportMode, clearExportMode] = useFlag(false);

    const pagination = usePagination(({
        paginationKey: `${StorageKey}:${role}`,
        fetch: async (pageable: Pageable<Wallet>) => {
            const p = pageable;
            if (clubId !== undefined) {
                p.filter = {
                    ...p.filter,
                    clubId
                };
            }
            return await list(p, WalletType.Club);
        }
    }));

    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('wallets.title'), onClick: () => { navigate('/wallets/club'); } },
                        { label: t('wallets.types.Club') }
                    ]}
                />
            )}
            title={(
                <Title
                    title={`${t('wallets.title')} ${role === UserRole.Root ? `(${t('wallets.types.Club')})` : ''}`}
                    actions={(
                        <Button
                            color="primary"
                            startDecorator={<IosShareRoundedIcon />}
                            size="sm"
                            onClick={setExportMode}
                            disabled={role === UserRole.User}
                        >
                            {t('actions.export')}
                        </Button>
                    )}
                />
            )}
        >
            <DataTable
                {...pagination}
                stickyLastColumn
                i18n={{
                    ...useTableI18n(),
                    searchLabel: t('wallets.search')
                }}
                headers={[
                    { label: t('accounts.singular'), sortKey: ['account.lastName', 'account.firstName'] as any, width: 120 },
                    { label: t('common.balance'), sortKey: ['amount'], width: 100 },
                    ...(role === UserRole.Root
                            ? [{ label: t('clubs.singular'), sortKey: ['club.name'], width: 180 }]
                            : []
                    ),
                    { label: t('common.createdAt'), sortKey: ['createdAt'], width: 100 },
                    { label: '', width: 60 },
                ]}
                renderTableRow={wallet => <TableRow key={wallet.id} {...{ wallet }} />}
                renderListRow={wallet => <ListRow key={wallet.id}  {...{ wallet }} />}
                renderFilter={(onFilter, filter) => <Filter {...{ onFilter, filter }} />}
            />

            <WalletExportModal
                intlWallets={false}
                open={exportMode}
                onCancel={clearExportMode}
            />
        </Body>
    );
}

function TableRow({wallet: {id, accountId, accountName, clubName, clubId, amount, createdAt}}: { wallet: Wallet }) {
    const {language} = useLocalization();
    const {role} = useAuthContext();
    return (
        <tr>
            <td>
                <RouterLink
                    href={`/wallets/${id}`}
                    sx={{
                        gap: 2,
                        flexDirection: 'row',
                        alignItems: 'center'
                    }}
                >
                    <Avatar size="sm" src={`/api/accounts/${accountId}/avatar`}/>
                    <Typography level="body-xs" noWrap>
                        <strong>{notBlank(accountName) ? accountName : id}</strong>
                    </Typography>
                </RouterLink>
            </td>
            <td>
                <Typography
                    level="body-xs"
                    noWrap
                    startDecorator={<EuroRoundedIcon/>}
                >
                    {displayAmount(amount)}
                </Typography>
            </td>
            {role === UserRole.Root && (
                <td>
                    {notBlank(clubName) && notBlank(clubId) && <ClubLabel label={clubName as string} clubId={clubId as string} noWrap/>}
                </td>
            )}
            <td>
                <Typography level="body-xs">
                    <DateTimeDisplay value={createdAt} locale={language} />
                </Typography>
            </td>
            <td onClick={event_ => {
                event_.stopPropagation();
            }}>
                <ItemMenu
                    {...{id}}
                />
            </td>
        </tr>
    );
}

function ListRow({wallet: {id, accountId, accountName, clubName, clubId, amount}}: { wallet: Wallet }) {
    const navigate = useNavigate();
    const {role} = useAuthContext();
    return (
        <List
            size="sm"
            sx={{
                '--ListItem-paddingX': 0,
            }}
        >
            <ListItemButton
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    '&:hover': {
                        background: 'none !important'
                    }
                }}
                onClick={() => {
                    navigate(`/account-groups/${id}`);
                }}
            >
                <ListItemContent sx={{display: 'flex', gap: 2, alignItems: 'start'}}>
                    <ListItemDecorator>
                        <Avatar size="sm" src={`/api/accounts/${accountId}/avatar`} />
                    </ListItemDecorator>
                    <div>
                        <Typography fontWeight={600} gutterBottom sx={{mt: 0.5}}>{notBlank(accountName) ? accountName : id}</Typography>
                        <Typography
                            level="body-xs"
                            startDecorator={<EuroRoundedIcon />}
                        >
                            {displayAmount(amount)}
                        </Typography>
                        {(notBlank(clubName) && notBlank(clubId) && role === UserRole.Root) && <ClubLabel label={clubName as string} clubId={clubId as string} />}
                    </div>
                </ListItemContent>
            </ListItemButton>
            <ListDivider />
        </List>
    );
}

function Filter({onFilter, filter = {}}: { onFilter: (filter: Record<string, any>) => void, filter: Record<string, any>|undefined }) {
    const {t} = useLocalization();
    const {role} = useAuthContext();

    const [state, setState] = useState('');

    useEffect(() =>
        {
            if (typeof filter.state === 'string'
                && filter.state !== state
            ) {
                setState(filter.state);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filter]);

    function handleState(s: string) {
        setState(s);
        onFilter({
            ...filter,
            state: s
        });
    }

    function handleClubId(clubId?: string) {
        onFilter({
            ...filter,
            clubId
        });
    }

    return (
        <>
            <FormControl size="sm">
                <FormLabel>{t('common.state')}</FormLabel>
                <Select
                    size="sm"
                    slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                    value={state}
                    onChange={(_, newValue) => { handleState(newValue ?? ''); }}
                >
                    <Option value="">{t('common.all')}</Option>
                    {Object.values(WalletFilterState).map(s => (
                        <Option key={s} value={s}>{t(`wallets.states.${s}`)}</Option>
                    ))}
                </Select>
            </FormControl>
            {role === UserRole.Root && (
                <ClubSelect
                    small
                    defaultClubId={filter.clubId}
                    onChange={handleClubId}
                />
            )}
        </>
    );
}

interface ItemMenuProperties {
    id: string;
}

function ItemMenu({id}: ItemMenuProperties) {
    const {t} = useLocalization();
    const navigate = useNavigate();

    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <Dropdown open={menuOpen} onOpenChange={(event_, open) => { setMenuOpen(open); }}>
            <MenuButton
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
            >
                <MoreHorizRoundedIcon />
            </MenuButton>
            <Menu size="sm" sx={{ minWidth: 140 }} >
                <MenuItem
                    onClick={event_ =>
                    { handleExplicitClick(event_, () => { navigate(`/wallets/${id}`); }); }
                    }
                >
                    <ListItemDecorator>
                        <VisibilityRoundedIcon />
                    </ListItemDecorator>
                    {t('actions.view')}
                </MenuItem>
            </Menu>
        </Dropdown>
    );
}