import type React from 'react';
import dayjs from 'dayjs';
import {decode} from 'js-base64';
import {enc, HmacSHA512} from 'crypto-js';
import {type Address, TimeUnit} from './data/common';
import {useLocalization} from './context/localization';

export function notBlank<T>(value: T) {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return value !== null && value !== undefined && `${value}`.trim() !== '';
}

export function notNull<T>(value: T) {
    return value !== null && value !== undefined;
}

export function handleExplicitClick(event_: React.MouseEvent<HTMLDivElement|HTMLButtonElement, MouseEvent>, function_: () => void) {
    event_.stopPropagation();
    function_();
}

export function displayAddressLine({addressLine1, addressLine2, zipCode, city, country}: Address) {
    return [addressLine1, addressLine2, `${zipCode} ${city}`, country]
        .filter(notBlank)
        .join(', ');
}

export function displayTimeUnit(unit: TimeUnit) {
    switch (unit) {
        case TimeUnit.Hour: { return 'h';
        }
        case TimeUnit.Minute: { return 'm';
        }
        case TimeUnit.Second: { return 's';
        }
        case TimeUnit.Millisecond: { return 'ms';
        }
        default: {
            return unit;
        }
    }
}

export function toDatetimeString(datetime: string) {
    return dayjs(datetime).toDate().toLocaleString();
}

export function toDateString(datetime: string) {
    return dayjs(datetime).toDate().toLocaleDateString();
}

export function displayAmount(amount: number) {
    const {language} = useLocalization();
    switch (language) {
        case 'de': {
            return amount.toFixed(2).replace('.', ',');
        }
        default: {
            return amount.toFixed(2);
        }
    }
}

export enum IsoDateFormat {
    Date = 'YYYY-MM-DD',
    Time = 'HH:mm:ss',
}

export function decodeBase64(value: string) {
    return decode(value);
}

export function hashPassword(rawPassword: string) {
    return enc.Base64.stringify(HmacSHA512(rawPassword, rawPassword));
}

export function formatExcelAmount(value: number) {
    return value.toFixed(2);
}

export function formatExcelDateTime(timestamp: string|undefined) {
    return (timestamp === undefined)
        ? ''
        : toDatetimeString(timestamp);
}