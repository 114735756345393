import {useAsync} from 'react-async-hook';
import {Chip, Divider, Modal, ModalClose, ModalDialog, Stack, Typography} from '@mui/joy';
import React from 'react';
import {type AccountTransaction, TransactionType} from '../../data/statistic';
import {useLocalization} from '../../context/localization';
import {useClubApi} from '../../data/club';
import {PaddedText} from '../../component/padded-text';
import {displayAmount, toDatetimeString, notBlank} from '../../utils';

export function TransactionModal({transaction, onClose}: { transaction: AccountTransaction, onClose: () => void }) {
    const {
        timestamp,
        clearedAt,
        type,
        data,
        note,
        clubId,
        clerkId,
        clerkName,
        walletId,
        walletType,
        accountId,
        billing,
        deviceName
    } = transaction;

    const {t} = useLocalization();
    const {find} = useClubApi();

    const {result: club} = useAsync(async () => clubId === undefined ? undefined : await find(clubId), [clubId]);

    return (
        <Modal open onClose={onClose}>
            <ModalDialog maxWidth="sm">
                <ModalClose />
                <Typography
                    component="div"
                    level="title-lg"
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        gap: 1,
                    }}
                >
                    {t('transactions.singular')}
                    <Chip color="primary" variant="solid">{data.id}</Chip>
                </Typography>
                <Divider />
                <Stack
                    sx={{
                        width: 290,
                        alignItems: 'stretch',
                    }}
                >
                    {notBlank(data.stripeId)
                        ? (
                            <>
                                <PaddedText bold text="Pin Point GmbH" />
                                <PaddedText text="Wildenhag Nr. 87" />
                                <PaddedText text="A-4881 Strass im Attergau" />
                                <PaddedText text="Tel.: +43 664 100 78 80" />
                            </>
                        )
                        : (
                            <>
                                <PaddedText bold text={club?.name} />
                                <PaddedText text={[club?.address?.zipCode, club?.address?.city].filter(notBlank).join(' ')} />
                                <PaddedText text={club?.address?.addressLine1} />
                                {notBlank(club?.address?.addressLine2) && <PaddedText text={club?.address?.addressLine2} />}
                                <PaddedText text={`Tel.: ${club?.contact?.phone}`} />
                            </>
                        )
                    }

                    <PaddedText text="" />
                    <PaddedText text="..........." />
                    <PaddedText text="" />

                    <PaddedText right text={`${t('common.timestamp')}: ${toDatetimeString(timestamp)}`} />

                    <PaddedText text="" />

                    <PaddedText bold text={`Trans.-Id: ${data.id}`} />

                    <PaddedText text="" />

                    <PaddedText leadingText={note} trailingText={`€ ${displayAmount(data.amount)}`} />
                    <PaddedText left text="------------" />
                    <PaddedText bold leadingText={t('transactions.sum').toUpperCase()} trailingText={`€ ${displayAmount(data.amount)}`} />

                    <PaddedText text="" />
                    <PaddedText text="" />

                    {notBlank(clearedAt)
                        ? (
                            <>
                                {type === TransactionType.ConsumeCredit && <PaddedText left text={t('transactions.paidLabel')} />}
                                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                                <PaddedText leadingText={t('transactions.clearedAt')} trailingText={toDatetimeString(clearedAt!)} />
                                <PaddedText text="" />
                            </>
                        )
                        : (
                            <>
                                <PaddedText left text={t('transactions.pendingLabel')} />
                                <PaddedText text="" />
                            </>
                        )
                    }

                    {notBlank(data.purpose)
                        ? (
                            <>
                                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                                <PaddedText left bold text={t(`transactions.purposes.${data.purpose!}`)} />

                                {data.purpose === 'Bvm' && (
                                    <>
                                        <PaddedText leadingText={t('devices.singular')} trailingText={deviceName ?? ''} />
                                        <PaddedText right text={`${data.value}x ${data.portName}`} />
                                        {data.ballQuantity !== undefined && <PaddedText right text={`${t('devices.quantity.Balls')}: ${data.ballQuantity}`} />}
                                        <PaddedText text="" />
                                    </>
                                )}


                            </>
                        )
                        : <PaddedText left bold text={t(`transactions.types.${type}`)} />
                    }

                    {notBlank(clerkName) && (
                        <>
                            <PaddedText leadingText={t('transactions.clerk')} trailingText={clerkId ?? '-'} />
                            <PaddedText right text={clerkName} />
                            <PaddedText text="" />
                        </>
                    )}

                    <PaddedText leadingText={t('accounts.singular')} trailingText={accountId} />
                    <PaddedText right text={[billing.lastName, billing.firstName].filter(notBlank).join(', ')} />
                    {notBlank(billing.address?.addressLine1) && <PaddedText right text={billing.address?.addressLine1} />}
                    {notBlank(billing.address?.addressLine2) && <PaddedText right text={billing.address?.addressLine2} />}
                    {(notBlank(billing.address?.zipCode) || notBlank(billing.address?.city)) && <PaddedText right text={[billing.address?.zipCode, billing.address?.city].filter(notBlank).join(' ')} />}
                    {notBlank(billing.address?.country) && <PaddedText right text={billing.address?.country?.toUpperCase()} />}

                    <PaddedText text="" />

                    <PaddedText leadingText={t('wallets.singular')} trailingText={`${walletId} (${t(`wallets.types.${walletType}`)})`} />
                </Stack>
            </ModalDialog>
        </Modal>
    );
}