import React, {useState} from 'react';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {useAsync} from 'react-async-hook';
import {Body, Breadcrumbs, Title, useFlag} from '@mlyngvo/common-ui';
import {
    Avatar,
    Box,
    Button,
    Chip,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemContent,
    Option,
    Sheet,
    Stack,
    Switch,
    Tooltip,
    Typography
} from '@mui/joy';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import MeetingRoomRoundedIcon from '@mui/icons-material/MeetingRoomRounded';
import SportsGolfRoundedIcon from '@mui/icons-material/SportsGolfRounded';
import AvTimerRoundedIcon from '@mui/icons-material/AvTimerRounded';
import PauseCircleOutlineRoundedIcon from '@mui/icons-material/PauseCircleOutlineRounded';
import ScatterPlotRoundedIcon from '@mui/icons-material/ScatterPlotRounded';
import {useLocalization} from '../../context/localization';
import {
    type Device,
    type DevicePortConfig,
    type DevicePortRequest,
    DeviceType,
    type PortNumber,
    useDeviceApi
} from '../../data/device';
import {useClubApi} from '../../data/club';
import {StatusChip} from '../../component/status-chip';
import {DeviceTypeChip} from './device-type-chip';
import {displayAmount, displayTimeUnit, notBlank, notNull} from '../../utils';
import {TimeUnit} from '../../data/common';
import {Input} from '../../component/input';
import {Select} from '../../component/select';
import {FormModal} from '../../component/form-modal';
import {AuthWrapper, useAuthContext} from '../../context/auth';
import {UserRole} from '../../data/user';
import {ClubTypeChip} from '../club';
import {RouterLink} from '../../component/link';
import EuroRoundedIcon from "@mui/icons-material/EuroRounded";

export function DeviceDetails() {
    return (
        <AuthWrapper>
            <ContentView />
        </AuthWrapper>
    );
}

function ContentView() {
    const {id} = useParams<{id: string}>();
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role} = useAuthContext();
    const {find, updatePort, setPortActive} = useDeviceApi();

    const {result: device, error, loading, execute} = useAsync(async () =>
            (id === undefined) ? undefined : await find(id)
        , [id]);

    async function handleSetPortActive(portNr: PortNumber, active: boolean) {
        if (id !== undefined) {
            try {
                await setPortActive(id, portNr, active);
            } catch (error_) {
                console.error('Failed to set port active', error_);
            }
        }
    }

    async function handleUpdatePort(portNr: PortNumber, request: DevicePortRequest) {
        if (id !== undefined) {
            try {
                await updatePort(id, portNr, request);
                await execute();
            } catch (error_) {
                console.error('Failed to update port', error_);
            }
        }
    }

    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('devices.title'), onClick: () => { navigate('/devices'); } },
                        { label: device === undefined ? t('common.details') : device.name }
                    ]}
                />
            )}
            title={(
                <Title
                    title={device === undefined ? t('devices.singular') : device.name}
                    actions={(
                        <Button
                            color="primary"
                            startDecorator={<EditRoundedIcon />}
                            size="sm"
                            onClick={() => { navigate(`/devices/${device?.id}/edit`); }}
                            disabled={role === UserRole.User}
                        >
                            {t('actions.edit')}
                        </Button>
                    )}
                />
            )}
            {...{ error, loading }}
        >
            {device !== undefined && (
                <Box
                    mt={2}
                >
                    <Stack
                        direction={{ xs: 'column', lg: 'row' }}
                        columnGap={3}
                        rowGap={3}
                        alignItems={{ xs: 'stretch', lg: 'flex-start' }}
                    >
                        <Stack
                            direction="column"
                            rowGap={3}
                            flexGrow={1}
                        >
                            <DeviceMeta {...device} />
                            <DetailsInfos {...device} />
                            <DeviceSettings {...device} />
                        </Stack>
                        <Stack
                            direction="column"
                            rowGap={3}
                            flexGrow={1}
                        >
                            {[device.port0, device.port1, device.port2, device.port3].map((port, index) => (
                                <DevicePortSettings
                                    key={`port-${  index}`}
                                    type={device.type}
                                    portNr={index as PortNumber}
                                    port={port}
                                    onStatusChange={handleSetPortActive}
                                    onPortChange={handleUpdatePort}
                                />
                            ))}
                        </Stack>
                    </Stack>
                </Box>
            )}
        </Body>
    );
}

type DeviceMetaProperties = Pick<Device, 'id'|'type'|'model'|'serialNumber'>;

function DeviceMeta({id, type, model, serialNumber}: DeviceMetaProperties) {
    const {t} = useLocalization();

    return (
        <Sheet
            variant="outlined"
            sx={{
                p: 2,
                flexGrow: 1
            }}
        >
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'flex-start', sm: 'center' }}
                height="100%"
                rowGap={2}
                columnGap={3}
            >
                <div>
                    <Typography level="body-xs" gutterBottom><strong>ID</strong></Typography>
                    <Chip
                        size="sm"
                        variant="outlined"
                        sx={theme => ({
                            minWidth: 80,
                            textAlign: 'center',
                            fontFamily: theme.fontFamily.code,
                            fontSize: '0.63rem'
                        })}
                    >
                        {id}
                    </Chip>
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('common.type')}</strong></Typography>
                    <DeviceTypeChip type={type} />
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('devices.model')}</strong></Typography>
                    {model}
                </div>
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('devices.serialNumber')}</strong></Typography>
                    {serialNumber}
                </div>
            </Stack>
        </Sheet>
    );
}

type DeviceInfosProperties = Pick<Device, 'name'|'clubId'>;

function DetailsInfos({name, clubId}: DeviceInfosProperties) {
    const {t} = useLocalization();
    const {role} = useAuthContext();
    const {find} = useClubApi();

    const {result: club} = useAsync(async () =>
        (role === UserRole.Root)
            ? await find(clubId)
            : undefined
        , [clubId, role]);

    return (
        <Sheet
            variant="outlined"
            sx={{ flex: 1 }}
        >
            <Box p={2}>
                <Typography level="title-md">{t('common.details')}</Typography>
                <Typography level="body-sm">{t('devices.detailsDescription')}</Typography>
            </Box>
            <Divider />
            <Stack
                p={2}
                flexDirection="column"
                rowGap={2}
            >
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('common.name')}</strong></Typography>
                    <Typography>{name}</Typography>
                </div>
                {role === UserRole.Root && (
                    <div>
                        <Typography level="body-xs" gutterBottom><strong>{t('clubs.singular')}</strong></Typography>
                        <List>
                            {club !== undefined && (
                                <ListItem
                                    component={RouterLink}
                                    href={`/clubs/${club.id}`}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 2,
                                        borderRadius: 7,
                                        py: 1,
                                    }}
                                >
                                    <Avatar size="sm" src={club.logoUrl}/>
                                    <ListItemContent
                                        component={Stack}
                                        direction="column"
                                        gap={0.5}
                                    >
                                        <Typography level="body-xs">{club.name}</Typography>
                                        <ClubTypeChip type={club.type}/>
                                    </ListItemContent>
                                    <KeyboardArrowRightRoundedIcon/>
                                </ListItem>
                            )}
                        </List>
                    </div>
                )}
            </Stack>
        </Sheet>
    );
}

type DeviceSettingsProperties = Pick<Device, 'type' | 'rangefeeRequired' | 'offlineUsageAllowed' | 'openOnConnectEnabled'>;

function DeviceSettings({type, rangefeeRequired, offlineUsageAllowed, openOnConnectEnabled}: DeviceSettingsProperties) {
    const {t} = useLocalization();

    return (
        <Sheet
            variant="outlined"
            sx={{flex: 1}}
        >
            <Box p={2}>
                <Typography level="title-md">{t('common.settings')}</Typography>
                <Typography level="body-sm">{t('devices.settingsDescription')}</Typography>
            </Box>
            <Divider/>
            <Stack
                p={2}
                flexDirection="column"
                rowGap={2}
            >
                <Box sx={{opacity: type === DeviceType.BallVendingMachine ? undefined : 0.5}}>
                    <Typography
                        level="body-md"
                        gutterBottom
                        startDecorator={<SportsGolfRoundedIcon/>}
                    >
                        {t('devices.bvmOptions')}
                    </Typography>
                    <div>
                        <Typography level="body-xs"><strong>{t('devices.rangefeeRequired')}</strong></Typography>
                        <StatusChip enabled={rangefeeRequired}/>
                    </div>
                </Box>

                <Divider />

                <Box sx={{ opacity: type === DeviceType.Entrance ? undefined : 0.5 }}>
                    <Typography
                        level="body-md"
                        gutterBottom
                        startDecorator={<MeetingRoomRoundedIcon />}
                    >
                        {t('devices.entranceOptions')}
                    </Typography>
                    <Stack
                        direction="column"
                        gap={1}
                    >
                        <div>
                            <Typography level="body-xs"><strong>{t('devices.offlineUsageAllowed')}</strong></Typography>
                            <StatusChip enabled={offlineUsageAllowed}/>
                        </div>
                        <div>
                            <Typography
                                level="body-xs"><strong>{t('devices.openOnConnectEnabled')}</strong></Typography>
                            <StatusChip enabled={openOnConnectEnabled}/>
                        </div>
                    </Stack>
                </Box>
            </Stack>
        </Sheet>
    );
}

interface FormElements extends HTMLFormControlsCollection {
    name: HTMLInputElement;
    switchingTime: HTMLInputElement;
    switchDelay: HTMLInputElement;
    amount: HTMLInputElement;
    amountIntlWallet: HTMLInputElement;
    quantity: HTMLInputElement;
}

interface PortEditorFormElement extends HTMLFormElement {
    readonly elements: FormElements;
}

interface DevicePortSettingsProperties {
    type: DeviceType;
    portNr: PortNumber;
    port: DevicePortConfig;
    onStatusChange: (portNr: PortNumber, active: boolean) => Promise<void>;
    onPortChange: (portNr: PortNumber, request: DevicePortRequest) => Promise<void>;
}

function DevicePortSettings(props: DevicePortSettingsProperties) {
    const {
        type,
        portNr,
        port: {
            active,
            name,
            switchingTime,
            switchingTimeUnit: pSwitchingTimeUnit,
            switchDelay,
            switchDelayUnit: pSwitchDelayUnit,
            quantity,
            amount,
            amountIntlWallet
        },
        onStatusChange,
        onPortChange
    } = props;
    const {t} = useLocalization();
    const {role} = useAuthContext();

    const [checked, setChecked] = useState(active);

    const [switchingTimeUnit, setSwitchingTimeUnit] = useState(pSwitchingTimeUnit);
    const [switchDelayUnit, setSwitchDelayUnit] = useState(pSwitchDelayUnit);
    const [editModalOpen, setEditModalOpen, clearEditModalOpen] = useFlag(false);

    function handleCheck(value: boolean) {
        onStatusChange(portNr, value)
            .then(() => { setChecked(value); })
            .catch(console.error);
    }

    async function handleSubmit(event: React.FormEvent<PortEditorFormElement>) {
        event.preventDefault();

        const formElements = event.currentTarget.elements;

        const data: DevicePortRequest = {
            name: formElements.name.value,
            switchingTime: formElements.switchingTime.valueAsNumber ?? 0,
            switchingTimeUnit,
            switchDelay: formElements.switchDelay.valueAsNumber ?? 0,
            switchDelayUnit,
            amount: formElements.amount.valueAsNumber,
            amountIntlWallet: formElements.amountIntlWallet.valueAsNumber,
            quantity: formElements.quantity.valueAsNumber
        };

        await onPortChange(portNr, data);
        clearEditModalOpen();
    }

    return (
        <>
            <Sheet
                variant="outlined"
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'stretch',
                    rowGap: 2,
                    py: 2
                }}
            >
                <Stack
                    px={2}
                    flex={1}
                    flexDirection="row"
                    alignItems="center"
                    columnGap={1}
                >
                    <Avatar variant="outlined"><code>P{portNr}</code></Avatar>
                    <Typography
                        level="title-md"
                        flexGrow={1}
                    >
                        Port #{portNr}
                    </Typography>
                    <Switch
                        checked={checked}
                        onChange={event_ => { handleCheck(event_.target.checked); }}
                        color={checked ? 'success' : 'neutral'}
                        variant={checked ? 'solid' : 'solid'}
                        startDecorator={<StatusChip {...{ enabled: checked }} />}
                        slotProps={{
                            input: {
                                sx: {
                                    zIndex: 9
                                }
                            },
                        }}
                        disabled={role === UserRole.User}
                    />
                </Stack>
                <Stack
                    px={2}
                >
                    <Typography gutterBottom>{notBlank(name) ? name : '-'}</Typography>
                    {type === DeviceType.BallVendingMachine && (
                        <>
                            <Typography level="body-md" startDecorator={<EuroRoundedIcon fontSize="inherit" />}>{displayAmount(amount ?? 0)}</Typography>
                            {role === UserRole.Root && notNull(amountIntlWallet) && (
                                <Typography level="body-md" startDecorator={<EuroRoundedIcon fontSize="inherit" />} endDecorator={<Chip size="sm" variant="outlined" color="primary">Intl. {t('wallets.singular')}</Chip>}>{displayAmount(amountIntlWallet as number)}</Typography>
                            )}
                        </>
                    )}
                    <Divider sx={{ my: 2, mx: -2 }} />
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                    >
                        <Tooltip title={t('devices.switchingTime')} placement="top">
                            <Typography
                                level="body-sm"
                                startDecorator={<AvTimerRoundedIcon />}
                            >
                                {switchingTime}{displayTimeUnit(switchingTimeUnit)}
                            </Typography>
                        </Tooltip>
                        {type === DeviceType.BallVendingMachine && (
                            <>
                                <Tooltip title={t('devices.switchDelay')} placement="top">
                                    <Typography
                                        level="body-sm"
                                        startDecorator={<PauseCircleOutlineRoundedIcon />}
                                    >
                                        {switchDelay}{displayTimeUnit(switchDelayUnit)}
                                    </Typography>
                                </Tooltip>
                                <Tooltip title={t('common.quantity')} placement="top">
                                    <Typography
                                        level="body-sm"
                                        startDecorator={<ScatterPlotRoundedIcon />}
                                    >
                                        {quantity} {t('devices.quantity.Balls')}
                                    </Typography>
                                </Tooltip>
                            </>
                        )}
                        <Box mx={1} flexGrow={1} />
                        <IconButton
                            size="sm"
                            variant="outlined"
                            color="primary"
                            onClick={setEditModalOpen}
                            disabled={role === UserRole.User}
                        >
                            <EditRoundedIcon fontSize="inherit" />
                        </IconButton>
                    </Stack>
                </Stack>
            </Sheet>
            <FormModal
                open={editModalOpen}
                onCancel={clearEditModalOpen}
                onSave={handleSubmit}
            >
                <Typography level="title-lg">
                    {t('actions.edit')}
                </Typography>
                <Divider />
                <Grid
                    container
                    spacing={2}
                    sx={{ py: 1 }}
                >
                    <Grid xs={12}>
                        <Typography>Port #{portNr}</Typography>
                    </Grid>
                    <Grid xs={12}>
                        <Input
                            label={t('common.description')}
                            InputProps={{
                                name: 'name',
                                defaultValue: name
                            }}
                            FormControlProps={{
                                required: true
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={8}>
                        <Input
                            label={t('devices.switchingTime')}
                            InputProps={{
                                type: 'number',
                                inputMode: 'numeric',
                                slotProps: {
                                    input: {
                                        step: '.01'
                                    }
                                },
                                name: 'switchingTime',
                                defaultValue: switchingTime,
                                startDecorator: <AvTimerRoundedIcon />
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={4}>
                        <Select
                            label={t('common.unit')}
                            options={Object.values(TimeUnit)}
                            renderOption={o => (
                                <Option key={o} value={o}>{displayTimeUnit(o)}</Option>
                            )}
                            SelectProps={{
                                placeholder: t('hints.select'),
                                value: switchingTimeUnit,
                                onChange: (_, value) => {
                                    setSwitchingTimeUnit(value ?? TimeUnit.Millisecond);
                                }
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={8} sx={{ display: type === DeviceType.BallVendingMachine ? undefined : 'none' }}>
                        <Input
                            label={t('devices.switchDelay')}
                            InputProps={{
                                type: 'number',
                                inputMode: 'numeric',
                                slotProps: {
                                    input: {
                                        step: '.01'
                                    }
                                },
                                name: 'switchDelay',
                                defaultValue: switchDelay,
                                startDecorator: <PauseCircleOutlineRoundedIcon />
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={4} sx={{ display: type === DeviceType.BallVendingMachine ? undefined : 'none' }}>
                        <Select
                            label={t('common.unit')}
                            options={Object.values(TimeUnit)}
                            renderOption={o => (
                                <Option key={o} value={o}>{displayTimeUnit(o)}</Option>
                            )}
                            SelectProps={{
                                placeholder: t('hints.select'),
                                value: switchDelayUnit,
                                onChange: (_, value) => {
                                    setSwitchDelayUnit(value ?? TimeUnit.Millisecond);
                                }
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={6} sx={{ display: type === DeviceType.BallVendingMachine ? undefined : 'none' }}>
                        <Stack
                            direction="column"
                            gap={2}
                        >
                            <Input
                                label={t('common.amount')}
                                InputProps={{
                                    startDecorator: '€',
                                    type: 'number',
                                    inputMode: 'numeric',
                                    slotProps: {
                                        input: {
                                            step: '.01'
                                        }
                                    },
                                    name: 'amount',
                                    defaultValue: amount
                                }}
                            />
                            <Input
                                label={`${t('common.amount')} (Intl. ${t('wallets.singular')})`}
                                InputProps={{
                                    startDecorator: '€',
                                    type: 'number',
                                    inputMode: 'numeric',
                                    slotProps: {
                                        input: {
                                            step: '.01'
                                        }
                                    },
                                    name: 'amountIntlWallet',
                                    defaultValue: amountIntlWallet,
                                }}
                                FormControlProps={{
                                    sx: {
                                        display: role === UserRole.Root ? 'flex' : 'none'
                                    }
                                }}
                            />
                        </Stack>
                    </Grid>
                    <Grid xs={12} sm={6} sx={{ display: type === DeviceType.BallVendingMachine ? undefined : 'none' }}>
                        <Input
                            label={`${t('common.quantity')} (${t('devices.quantity.Balls')})`}
                            InputProps={{
                                endDecorator: <ScatterPlotRoundedIcon />,
                                type: 'number',
                                inputMode: 'numeric',
                                name: 'quantity',
                                defaultValue: quantity
                            }}
                        />
                    </Grid>
                </Grid>
            </FormModal>
        </>
    );
}

