import {useNavigate} from 'react-router-dom';
import {
    Body,
    Breadcrumbs,
    DataTable,
    type Pageable,
    Title,
    useFlag,
    usePagination,
    Select,
    DateTimeDisplay
} from '@mlyngvo/common-ui';
import {
    Avatar, Badge, Box,
    Chip, Divider,
    Dropdown,
    IconButton,
    List, ListDivider,
    ListItemButton,
    ListItemContent, ListItemDecorator,
    Menu,
    MenuButton,
    MenuItem, Option, Typography
} from '@mui/joy';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import MoreHorizRoundedIcon from '@mui/icons-material/MoreHorizRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import CorporateFareRoundedIcon from '@mui/icons-material/CorporateFareRounded';
import EuroRoundedIcon from '@mui/icons-material/EuroRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import {displayAddressLine, displayAmount, toDatetimeString, handleExplicitClick, notBlank, notNull} from '../../utils';
import {ConfirmDialog} from '../../component/confirm-dialog';
import {
    AccountFilterClubState,
    AccountFilterGroupState,
    AccountFilterState,
    type AccountListView,
    useAccountApi
} from '../../data/account';
import {useLocalization, useTableI18n} from '../../context/localization';
import {ClubSelect} from '../../component/club-select';
import {AccountCreator} from './creator';
import {DropdownButton} from '../../component/dropdown-button';
import {AccountExportModal} from './account-export-modal';
import {AuthWrapper, useAuthContext} from '../../context/auth';
import {UserRole} from '../../data/user';
import {RouterLink} from '../../component/link';
import {type Address} from '../../data/common';

const StorageKey = 'page-accounts';

export function AccountList() {
    return (
        <AuthWrapper>
            <ContentView />
        </AuthWrapper>
    );
}

function ContentView() {
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role, clubId} = useAuthContext();
    const {list} = useAccountApi();

    const [creatorMode, setCreatorMode, clearCreatorMode] = useFlag(false);
    const [exportMode, setExportMode, clearExportMode] = useFlag(false);

    const pagination = usePagination(({
        paginationKey: `${StorageKey}:${role}`,
        fetch: async (pageable: Pageable<AccountListView>) => {
            const p = pageable;
            if (clubId !== undefined) {
                p.filter = {
                    ...p.filter,
                    clubId
                };
            }
            return await list(p);
        }
    }));

    async function handleDelete() {
        try {
            await pagination.onReload();
        } catch (error) {
            console.error('Failed to handle delete', error);
        }
    }

    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('accounts.title') }
                    ]}
                />
            )}
            title={(
                <Title
                    title={t('accounts.title')}
                    actions={(
                        <DropdownButton
                            items={[
                                { label: t('accounts.addNew'), startDecorator: <AddRoundedIcon />, onClick: setCreatorMode },
                                ...(role === UserRole.User
                                    ? []
                                    : [{ label: `${t('accounts.title')} ${t('actions.export').toLowerCase()}`, onClick: setExportMode }]
                                )
                            ]}
                        />
                    )}
                />
            )}
        >
            <DataTable
                {...pagination}
                stickyLastColumn
                i18n={{
                    ...useTableI18n(),
                    searchLabel: t('accounts.search')
                }}
                headers={[
                    { label: t('common.name'), sortKey: ['lastName', 'firstName'], width: 160 },
                    { label: t('common.email'), sortKey: ['email'], width: 120 },
                    { label: t('common.createdAt'), sortKey: role === UserRole.Root ? ['createdAt'] : ['clubs.createdAt'], width: 80 },
                    ...(role === UserRole.Root
                            ? [
                                { label: `${t('common.balance')} (Intl.)`, width: 80 },
                                { label: t('clubs.singular'), sortKey: ['clubs.id.club.name'] as any, width: 180 },
                            ]
                            : [
                                { label: t('accountGroups.singular'), sortKey: ['clubs.accountGroup.name'], width: 180 },
                            ]
                    ),
                    { label: '', width: 60 },
                ]}
                renderTableRow={account => <TableRow key={account.id} {...{ account }} onDelete={handleDelete} />}
                renderListRow={account => <ListRow key={account.id}  {...{ account }} />}
                renderFilter={(onFilter, filter) => <Filter {...{ onFilter, filter }} />}
            />

            <AccountCreator
                open={creatorMode}
                onCancel={clearCreatorMode}
            />

            <AccountExportModal
                open={exportMode}
                onCancel={clearExportMode}
            />
        </Body>
    );
}

function TableRow({account: {id, firstName, lastName, email, address, clubs, avatarUrl, intlWallet, createdAt}, onDelete}: { account: AccountListView, onDelete: () => Promise<void> }) {
    const {t, language} = useLocalization();
    const {role} = useAuthContext();
    const {deleteAccount} = useAccountApi();

    const [deletionPromptOpen, setDeletionPromptOpen, clearDeletionPromptOpen] = useFlag(false);

    function handleDelete() {
        deleteAccount(id)
            .then(async () => {
                await onDelete();
                setDeletionPromptOpen();
            })
            .catch(console.error);
    }

    const clubEntries = useMemo(() => Object.values(clubs), [clubs]);
    const clubStateColor = useMemo(() => {
        if (clubEntries.length === 0) return 'success';

        const disabledCount = clubEntries.filter(e => !e.enabled).length;
        switch (disabledCount) {
            case 0: return 'success';
            case clubEntries.length: return 'danger';
            default: return 'warning';
        }
    }, [clubEntries]);
    const clubEntry = (clubEntries.length === 1) ? clubEntries[0] : undefined;

    return (
        <>
            <tr>
                <td>
                    <RouterLink
                        href={`/accounts/${id}`}
                        sx={{
                            gap: 2,
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        <Badge
                            color={clubStateColor} size="sm"
                            badgeInset="14%"
                        >
                            <Avatar size="sm" src={avatarUrl}/>
                        </Badge>
                        <Typography level="body-xs" noWrap>
                            {/* eslint-disable-next-line @typescript-eslint/strict-boolean-expressions */}
                            <strong>{[lastName, firstName].filter(notBlank).join(', ') || id}</strong>
                            <br/>
                            {notNull(address) ? displayAddressLine(address as Address) : '-'}
                        </Typography>
                    </RouterLink>
                </td>
                <td>
                    <Typography
                        component={RouterLink}
                        href={`/accounts/${id}`}
                        level="body-xs"
                        noWrap
                    >
                        {email}
                    </Typography>

                </td>
                <td>
                    <Typography level="body-xs" noWrap>
                        {role === UserRole.Root && <DateTimeDisplay value={createdAt} locale={language} />}
                        {role !== UserRole.Root && clubEntry !== undefined && (
                            <DateTimeDisplay value={clubEntry.createdAt} locale={language} />
                        )}
                    </Typography>
                </td>
                {role !== UserRole.Root && (
                    <td>
                        <Typography
                            level="body-xs"
                            noWrap
                        >
                            {clubEntries.length > 0 && notBlank(clubEntries[0].accountGroupId) && (
                                <Typography
                                    component={RouterLink}
                                    href={`/account-groups/${clubEntries[0].accountGroupId}`}
                                    level="body-xs"
                                    sx={{ pl: 2 }}
                                >
                                    {clubEntries[0].accountGroupName}
                                </Typography>
                            )}
                        </Typography>
                    </td>
                )}
                {role === UserRole.Root && (
                    <>
                        <td>
                            <Typography
                                level="body-xs"
                                noWrap
                                startDecorator={<EuroRoundedIcon/>}
                            >
                                {displayAmount(intlWallet?.amount ?? 0)}
                            </Typography>
                        </td>
                        <td>
                            {clubEntries.length > 0 && (
                                <Typography
                                    level="body-xs"
                                    startDecorator={<CorporateFareRoundedIcon sx={{fontSize: '1rem'}}/>}
                                    sx={{
                                        alignItems: 'flex-start'
                                    }}
                                >
                                    <Box
                                        component="span"
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            rowGap: 0.5
                                        }}
                                    >
                                        {clubEntries.length < 3 && clubEntries.map(({clubId, clubName, accountGroupId, accountGroupName}) => (
                                            <Fragment key={clubId} >
                                                <Typography component={RouterLink} href={`/clubs/${clubId}`} level="body-xs">
                                                    {clubName}
                                                </Typography>
                                                {notBlank(accountGroupId) && (
                                                    <Typography
                                                        component={RouterLink}
                                                        href={`/account-groups/${accountGroupId}`}
                                                        level="body-xs"
                                                        sx={{ pl: 2 }}
                                                    >
                                                        {accountGroupName}
                                                    </Typography>
                                                )}
                                            </Fragment>
                                        ))}
                                        {clubEntries.length > 2 && (
                                            <>
                                                <span>{clubEntries[0].clubName}</span>
                                                {notBlank(clubEntries[0].accountGroupId) && (
                                                    <Typography
                                                        component={RouterLink}
                                                        href={`/account-groups/${clubEntries[0].accountGroupId}`}
                                                        level="body-xs"
                                                        sx={{ pl: 2 }}
                                                    >
                                                        {clubEntries[0].accountGroupName}
                                                    </Typography>
                                                )}

                                                <Chip
                                                    size="sm"
                                                    variant="outlined"
                                                >
                                                    +{clubEntries.length - 1} {t('hints.more')}
                                                </Chip>
                                            </>
                                        )}
                                    </Box>
                                </Typography>
                            )}
                        </td>
                    </>
                )}
                <td onClick={event_ => {
                    event_.stopPropagation();
                }}>
                    <ItemMenu
                        {...{
                            id,
                            onDelete: setDeletionPromptOpen,
                        }}
                    />
                </td>
            </tr>
            <ConfirmDialog
                open={deletionPromptOpen}
                onCancel={clearDeletionPromptOpen}
                onConfirm={handleDelete}
                title={t('accounts.deleteTitle')}
                message={t('accounts.deletePrompt')}
                color="danger"
            />
        </>
    );
}

function ListRow({account: {id, firstName, lastName, email, address, clubs, avatarUrl}}: { account: AccountListView }) {
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {role} = useAuthContext();

    const clubEntries = useMemo(() => Object.values(clubs), [clubs]);
    const clubEntry = (clubEntries.length === 1) ? clubEntries[0] : undefined;
    const isActive = useMemo(() => {
        if (clubEntry !== undefined) return clubEntry.enabled;
        return true;
    }, [clubEntry]);
    return (
        <List
            size="sm"
            sx={{
                '--ListItem-paddingX': 0,
            }}
        >
            <ListItemButton
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    '&:hover': {
                        background: 'none !important'
                    }
                }}
                onClick={() => {
                    navigate(`/accounts/${id}`);
                }}
            >
                <ListItemContent sx={{display: 'flex', gap: 2, alignItems: 'start'}}>
                    <ListItemDecorator>
                        <Badge
                            color={isActive ? 'success' : 'danger'} size="sm"
                            badgeInset="14%"
                        >
                            <Avatar size="sm" src={avatarUrl}/>
                        </Badge>
                    </ListItemDecorator>
                    <div>
                        <Typography fontWeight={600}>
                            {[lastName, firstName].filter(notBlank).join(', ')}
                        </Typography>
                        <Typography
                            level="body-xs"
                            gutterBottom
                        >
                            {notNull(address) ? displayAddressLine(address as Address) : '-'}
                        </Typography>
                        <Typography
                            level="body-xs"
                            gutterBottom
                        >
                            {email}
                        </Typography>
                        {(role === UserRole.Root && clubEntries.length > 0) && (
                            <Typography
                                level="body-xs"
                                startDecorator={<CorporateFareRoundedIcon sx={{ fontSize: '1rem' }} />}
                                sx={{
                                    alignItems: 'flex-start'
                                }}
                            >
                                <Box
                                    component="span"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'flex-start',
                                        rowGap: 0.5
                                    }}
                                >
                                    {clubEntries.length < 3 && clubEntries.map(({clubId, clubName}) => (
                                        <span key={clubId}>{clubName}</span>
                                    ))}
                                    {clubEntries.length > 2 && (
                                        <>
                                            <span>{clubEntries[0].clubName}</span>
                                            <Chip size="sm" variant="outlined">+{clubEntries.length - 1} {t('hints.more')}</Chip>
                                        </>
                                    )}
                                </Box>
                            </Typography>
                        )}
                    </div>
                </ListItemContent>
            </ListItemButton>
            <ListDivider />
        </List>
    );
}

function Filter({onFilter, filter = {}}: { onFilter: (filter: Record<string, any>) => void, filter: Record<string, any>|undefined }) {
    const {t} = useLocalization();
    const {role} = useAuthContext();

    const [state, setState] = useState('');
    const [groupState, setGroupState] = useState('');
    const [clubState, setClubState] = useState('');

    useEffect(() =>
        {
            if (typeof filter.state === 'string'
                && filter.state !== state
            ) {
                setState(filter.state);
            }
            if (typeof filter.groupState === 'string'
                && filter.groupState !== groupState
            ) {
                setGroupState(filter.groupState);
            }
            if (typeof filter.clubState === 'string'
                && filter.clubState !== clubState
            ) {
                setClubState(filter.clubState);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [filter]);

    function handleState(r: string) {
        setState(r);
        onFilter({
            ...filter,
            state: r
        });
    }

    function handleGroupState(r: string) {
        setGroupState(r);
        onFilter({
            ...filter,
            groupState: r
        });
    }

    function handleClubState(r: string) {
        setClubState(r);
        onFilter({
            ...filter,
            clubState: r
        });
    }

    function handleClubId(clubId?: string) {
        onFilter({
            ...filter,
            clubId
        });
    }

    return (
        <>
            {role === UserRole.Root && (
                <Select
                    emptyValue
                    label={t('common.state')}
                    options={Object.values(AccountFilterState)}
                    renderOption={o => (
                        <Option key={o} value={o}>{t(`accounts.states.${o}`)}</Option>
                    )}
                    FormControlProps={{
                        size: 'sm'
                    }}
                    SelectProps={{
                        size: 'sm',
                        slotProps: {
                            button: {
                                sx: {
                                    whiteSpace: 'nowrap'
                                }
                            }
                        },
                        value: state as AccountFilterState,
                        onClear: () => { handleState(''); },
                        onChange: (_, value) => { handleState(value ?? ''); },
                    }}
                    i18n={{
                        allLabel: t('common.all')
                    }}
                />
            )}

            <Select
                emptyValue
                label={t('accountGroups.singular')}
                options={Object.values(AccountFilterGroupState)}
                renderOption={o => (
                    <Option key={o} value={o}>{t(`accounts.groupStates.${o}`)}</Option>
                )}
                FormControlProps={{
                    size: 'sm'
                }}
                SelectProps={{
                    size: 'sm',
                    slotProps: {
                        button: {
                            sx: {
                                whiteSpace: 'nowrap'
                            }
                        }
                    },
                    value: groupState as AccountFilterGroupState,
                    onClear: () => { handleGroupState(''); },
                    onChange: (_, value) => { handleGroupState(value ?? ''); },
                }}
                i18n={{
                    allLabel: t('common.all')
                }}
            />

            <Select
                emptyValue
                label={t('accounts.clubState')}
                options={Object.values(AccountFilterClubState)}
                renderOption={o => (
                    <Option key={o} value={o}>{t(`accounts.clubStates.${o}`)}</Option>
                )}
                FormControlProps={{
                    size: 'sm'
                }}
                SelectProps={{
                    size: 'sm',
                    slotProps: {
                        button: {
                            sx: {
                                whiteSpace: 'nowrap'
                            }
                        }
                    },
                    value: clubState as AccountFilterClubState,
                    onClear: () => { handleClubState(''); },
                    onChange: (_, value) => { handleClubState(value ?? ''); },
                }}
                i18n={{
                    allLabel: t('common.all')
                }}
            />

            {role === UserRole.Root && (
                <ClubSelect
                    small
                    defaultClubId={filter.clubId}
                    onChange={handleClubId}
                />
            )}
        </>
    );
}

interface ItemMenuProperties {
    id: string;
    onDelete: () => void;
}

function ItemMenu({id, onDelete}: ItemMenuProperties) {
    const {t} = useLocalization();
    const navigate = useNavigate();
    const {role} = useAuthContext();

    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <Dropdown open={menuOpen} onOpenChange={(event_, open) => { setMenuOpen(open); }}>
            <MenuButton
                slots={{ root: IconButton }}
                slotProps={{ root: { variant: 'plain', color: 'neutral', size: 'sm' } }}
            >
                <MoreHorizRoundedIcon />
            </MenuButton>
            <Menu size="sm" sx={{ minWidth: 140 }} >
                <MenuItem
                    onClick={event_ =>
                    { handleExplicitClick(event_, () => { navigate(`/accounts/${id}`); }); }
                    }
                >
                    <ListItemDecorator>
                        <VisibilityRoundedIcon />
                    </ListItemDecorator>
                    {t('actions.view')}
                </MenuItem>
                <MenuItem
                    onClick={event_ =>
                    { handleExplicitClick(event_, () => { navigate(`/accounts/${id}/edit`); }); }
                    }
                >
                    <ListItemDecorator>
                        <EditRoundedIcon />
                    </ListItemDecorator>
                    {t('actions.edit')}
                </MenuItem>
                {role === UserRole.Root && (
                    <>
                        <Divider />
                        <MenuItem
                            color="danger"
                            onClick={event_ =>
                            { handleExplicitClick(event_, () => { onDelete(); }); }
                            }
                        >
                            <ListItemDecorator>
                                <DeleteRoundedIcon />
                            </ListItemDecorator>
                            {t('actions.delete')}
                        </MenuItem>
                    </>
                )}
            </Menu>
        </Dropdown>
    );
}