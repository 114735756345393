import {utils, WorkBook, writeFile} from 'xlsx';
import React, {type FormEvent, useState} from 'react';
import {Divider, Grid, Typography} from '@mui/joy';
import {useLocalization} from '../../context/localization';
import {formatExcelAmount, formatExcelDateTime, notBlank} from '../../utils';
import {computeColumnLengths, formatCellAsNumber} from '../../excels';
import {FormModal} from '../../component/form-modal';
import {ClubSelect} from '../../component/club-select';
import {type ClubListView, useClubApi} from '../../data/club';
import {useAccountApi} from '../../data/account';
import {useAuthContext} from '../../context/auth';
import {UserRole} from '../../data/user';
import {Pageable} from "@mlyngvo/common-ui";

export function AccountExportModal({open, onCancel}: { open: boolean, onCancel: () => void }) {
    const {t} = useLocalization();
    const {role, clubId: authClubId} = useAuthContext();
    const {find, list: listClub} = useClubApi();
    const {list} = useAccountApi();

    const [clubId, setClubId] = useState(authClubId);

    async function exportToExcel(event_: FormEvent) {
        event_.preventDefault();

        const clubs = await prepareClubData(clubId);
        const workbook = utils.book_new();

        for (let club of clubs) {
            try {
                await processSheet(workbook, club);
            } catch (err) {
                console.error('failed', err)
            }
        }

        writeFile(workbook, `${t('accounts.title')}.xlsx`, { compression: true });

        onCancel();
    }

    async function prepareClubData(clubId?: string) {
        const ids: ClubListView[] = [];

        if (clubId !== undefined) {
            const club = await find(clubId);
            if (club !== undefined) {
                ids.push(club);
            }
        } else {
            const pageable: Pageable<ClubListView> = {
                size: 100,
                page: 0,
                sort: {
                    fields: ['name'],
                    order: 'asc'
                }
            }
            let counter = 0;
            let hasNext = true;
            while (hasNext) {
                const page = await listClub(pageable);
                ids.push(...(page?.content ?? []));
                counter += 1;
                pageable.page = counter;
                if (page?.isLast) {
                    hasNext = false;
                }
            }
        }
        return ids;
    }

    async function processSheet(workbook: WorkBook, club: ClubListView) {
        const worksheet = utils.json_to_sheet([]);

        let sheetName = club.name.trim();
        if (sheetName.length > 31) {
            sheetName = sheetName.substring(0, 28) + '...';
        }

        utils.book_append_sheet(workbook, worksheet, sheetName);

        utils.sheet_add_aoa(worksheet, [[`${t('accounts.title')}`]], { origin: 'A1' });
        utils.sheet_add_aoa(worksheet, [[`${t('clubs.singular')}: ${  club.name}`]], { origin: 'A2' });

        const headers = [
            '#',
            t('common.name'),
            t('accounts.registeredAt'),
            t('accountGroups.singular'),
            t('common.active'),
            t('common.address'),
            t('common.email'),
            t('common.phone'),
            `${t('common.balance')} (${t('wallets.types.Club')})`,
            `${t('common.balance')} (${t('wallets.types.International')})`
        ];

        let rows: Array<Record<string, string>> = [];
        let page = 0;
        let totalPage = 1;
        try {
            do {
                // eslint-disable-next-line no-await-in-loop
                const data = await list({
                    page,
                    size: 100,
                    filter: {
                        clubId: club.id,
                    }
                });
                if (data === undefined) {
                    console.error('Failed to fetch data.');
                    return;
                }

                rows.push(...data.content.map((at, index) => {
                    const {
                        createdAt,
                        enabled,
                        wallet,
                        accountGroupName
                    } = Object.values(at.clubs).find(v => club?.id === v.clubId) ?? {};
                    const {
                        title,
                        firstName,
                        lastName,
                        address: {
                            addressLine1,
                            addressLine2,
                            city,
                            zipCode,
                            country
                        } = {},
                        email,
                        phone,
                        intlWallet,
                    } = at;
                    return {
                        name: [title, lastName, firstName].filter(notBlank).join(', '),
                        registeredAt: formatExcelDateTime(createdAt),
                        accountGroup: accountGroupName ?? '',
                        active: enabled === true ? t('common.yes') : t('common.no'),
                        address: [addressLine1, addressLine2, `${city} ${zipCode}`, country].join(', '),
                        email: email ?? '',
                        phone: phone ?? '',
                        clubBalance: `${wallet === undefined ? '0' : formatExcelAmount(wallet.amount)}`,
                        intlBalance: `${intlWallet === undefined ? '0' : formatExcelAmount(intlWallet.amount)}`,
                        registrationTimestamp: createdAt ?? '',
                    };
                }));

                totalPage = data.totalPages;
                page += 1;
            } while (page < totalPage);
        } catch (error) {
            console.error('Failed to get data', error);
        }

        rows = rows
            .sort((a, b) => a.registrationTimestamp.localeCompare(b.registrationTimestamp))
            .map(({registrationTimestamp, ...rest}, index) => ({ index: (index + 1), ...rest }) as any);

        utils.sheet_add_json(worksheet, rows, { origin: 'A5' });
        utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });

        formatCellAsNumber(worksheet, 5, 0, rows.length);
        formatCellAsNumber(worksheet, 5, 8, rows.length, '0.00');
        formatCellAsNumber(worksheet, 5, 9, rows.length, '0.00');

        worksheet['!cols'] = computeColumnLengths(rows);
    }

    return (
        <FormModal
            open={open}
            onCancel={onCancel}
            onSave={exportToExcel}
            saveLabel={t('actions.export')}
            successMessage={t('hints.dataExported')}
        >
            <Typography level="title-lg">{t('actions.export')}</Typography>
            <Divider />
            <Grid container spacing={2}>
                <Grid xs={12}>
                    {role === UserRole.Root && (
                        <ClubSelect
                            // required
                            defaultClubId={clubId}
                            onChange={setClubId}
                        />
                    )}
                    {role !== UserRole.Root && (
                        <Typography>Ready to export.</Typography>
                    )}
                </Grid>
            </Grid>
        </FormModal>
    );
}