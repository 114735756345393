import React, {type FormEvent, useMemo, useState} from 'react';
import {useParams} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {useAsync} from 'react-async-hook';
import {
    Body,
    Breadcrumbs,
    DataTable,
    DateTimeDisplay,
    type Pageable,
    Title,
    useFlag,
    usePagination
} from '@mlyngvo/common-ui';
import {
    Avatar,
    Box,
    Button,
    Chip,
    Divider,
    Grid,
    List, ListDivider,
    ListItemButton,
    ListItemContent,
    Option,
    Sheet,
    Stack,
    Typography
} from '@mui/joy';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import EuroRoundedIcon from '@mui/icons-material/EuroRounded';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import RemoveCircleOutlineRoundedIcon from '@mui/icons-material/RemoveCircleOutlineRounded';
import DeveloperBoardRoundedIcon from '@mui/icons-material/DeveloperBoardRounded';
import {displayAmount, toDatetimeString, notBlank, notNull} from '../../utils';
import {useWalletApi, type Wallet, WalletType} from '../../data/wallet';
import {useLocalization} from '../../context/localization';
import {FormModal} from '../../component/form-modal';
import {RadioInput} from '../../component/radio-input';
import {type AccountTransaction, TransactionDirection, TransactionType} from '../../data/statistic';
import {Input} from '../../component/input';
import {Select} from '../../component/select';
import {type TransactionBookmark, useClubApi} from '../../data/club';
import {TransactionStatusChip} from '../statistic/transaction-status-chip';
import {TransactionTypeChip} from '../statistic/transaction-type-chip';
import {TransactionPurposeChip} from '../statistic/transaction-purpose-chip';
import {UserLabel} from '../../component/user-label';
import {ColoredAmount} from '../../component/colored-amount';
import {ClubLabel} from '../../component/club-label';
import {TransactionModal} from '../statistic/transaction-modal';
import {TransactionExportButton} from './transaction-export-button';

function displayBookmarkLabel(b: TransactionBookmark) {
    const chunks: string[] = [];
    if (notBlank(b.message)) chunks.push(b.message ?? '');
    if (notNull(b.amount)) chunks.push(`€${  b.amount ?? 0}`);
    return chunks.join(' - ');
}

export function WalletDetails() {
    const {id} = useParams<{id: string}>();
    const navigate = useNavigate();
    const {t} = useLocalization();
    const {find} = useWalletApi();

    const {result: wallet, error, loading, execute} = useAsync(async () =>
            (id === undefined) ? undefined : await find(id)
        , [id]);

    const {
        name, type, typePath
    } = useMemo(() =>
        ({
            name: `${t('wallets.singular')}: ${notBlank(wallet?.accountName) ? wallet?.accountName : wallet?.id}`,
            type: wallet?.type === WalletType.International ? t('wallets.types.International') : t('wallets.types.Club'),
            typePath: wallet?.type === WalletType.International ? '/intl' : '/club'
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [wallet]
    );
    return (
        <Body
            top={(
                <Breadcrumbs
                    onHomeClick={() => { navigate('/'); }}
                    items={[
                        { label: t('wallets.title'), onClick: () => { navigate(`/wallets${typePath}`); } },
                        { label: type, onClick: () => { navigate(`/wallets${typePath}`); } },
                        { label: name }
                    ]}
                />
            )}
            title={(
                <Title
                    title={name}
                />
            )}
            {...{ error, loading }}
        >
            {wallet !== undefined && (
                <Box
                    mt={2}
                >
                    <Stack
                        direction={{ xs: 'column', lg: 'row' }}
                        columnGap={3}
                        rowGap={3}
                        alignItems={{ xs: 'stretch', lg: 'flex-start' }}
                    >
                        <Stack
                            direction="column"
                            rowGap={3}
                            flexGrow={3}
                        >
                            <WalletInfos {...wallet} />
                        </Stack>
                        <Stack
                            direction="column"
                            rowGap={3}
                            flexGrow={1}
                        >
                            <WalletAmount
                                refresh={async () => { await execute(id); }}
                                {...wallet}
                            />
                        </Stack>
                    </Stack>
                    <Box my={3} />
                    <WalletTransactions {...wallet} />
                </Box>
            )}
        </Body>
    );
}

type WalletInfosProperties = Pick<Wallet, 'accountId'|'accountName'|'clubId'|'clubName'>

function WalletInfos({accountId, accountName, clubId, clubName}: WalletInfosProperties) {
    const {t} = useLocalization();
    const navigate = useNavigate();

    return (
        <Sheet
            variant="outlined"
            sx={{ flex: 1 }}
        >
            <Box p={2}>
                <Typography level="title-md">{t('common.details')}</Typography>
                <Typography level="body-sm">{t('wallets.detailsDescription')}</Typography>
            </Box>
            <Divider />
            <Stack
                p={2}
                flexDirection="column"
            >
                <div>
                    <Typography level="body-xs" gutterBottom><strong>{t('accounts.singular')}</strong></Typography>
                    <List>
                        <ListItemButton
                            sx={{
                                borderRadius: 7, py: 1,
                                display: 'flex', gap: 2, alignItems: 'center'
                            }}
                            onClick={() => { navigate(`/accounts/${accountId}`); }}
                        >
                            <Avatar size="sm" src={`/api/accounts/${accountId}/avatar`} />
                            <ListItemContent>
                                <Typography level="body-sm" noWrap><strong>{accountName}</strong></Typography>
                                <Chip size="sm" color="primary" variant="outlined" sx={{ fontFamily: 'monospace' }}>{accountId}</Chip>
                            </ListItemContent>
                            <KeyboardArrowRightRoundedIcon />
                        </ListItemButton>
                    </List>
                </div>
                {notBlank(clubId) && (
                    <div>
                        <Typography level="body-xs" gutterBottom><strong>{t('clubs.singular')}</strong></Typography>
                        <List>
                            <ListItemButton
                                sx={{
                                    borderRadius: 7, py: 1,
                                    display: 'flex', gap: 2, alignItems: 'center'
                                }}
                                onClick={() => {
                                    navigate(`/clubs/${clubId}`);
                                }}
                            >
                                <Avatar size="sm" src={`/api/clubs/${clubId}/logo`}/>
                                <ListItemContent>
                                    <Typography level="body-sm"><strong>{clubName}</strong></Typography>
                                    <Chip size="sm" color="neutral" variant="outlined" sx={{ fontFamily: 'monospace' }}>{clubId}</Chip>
                                </ListItemContent>
                                <KeyboardArrowRightRoundedIcon/>
                            </ListItemButton>
                        </List>
                    </div>
                )}
            </Stack>
        </Sheet>
    );
}

type WalletAmountProperties = Pick<Wallet, 'id'|'amount'|'clubId'> & { refresh: () => Promise<void> }

function WalletAmount({id, amount, clubId, refresh}: WalletAmountProperties) {
    const {t} = useLocalization();
    const {listTransactionBookmarks} = useClubApi();
    const {processWalletAmount} = useWalletApi();

    const {result: bookmarks} = useAsync(async () =>
        (notBlank(clubId))
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            ? await listTransactionBookmarks(clubId!)
            : undefined
        , [clubId]);

    const [modifyMode, setModifyMode, clearModifyMode] = useFlag(false);

    const [direction, setDirection] = useState<TransactionDirection>();
    const [formAmount, setFormAmount] = useState<number>();
    const [note, setNote] = useState('');

    const bookmarkOptions = useMemo(() =>
        bookmarks?.filter(b => b.direction === direction && !b.inAppPaymentExclusive) ?? []
        , [bookmarks, direction]);

    function showModifyMode(d: TransactionDirection) {
        setDirection(d);
        setModifyMode();
    }

    function handleDirectionChange(d: string) {
        setDirection(d as TransactionDirection);
    }

    function handleBookmarkSelect(b?: TransactionBookmark) {
        if (b !== undefined) {
            setFormAmount(b.amount ?? 0);
            setNote(b.message ?? '');
        }
    }

    async function handleSubmit(event_: FormEvent) {
        event_.preventDefault();

        if (direction !== undefined && formAmount !== undefined && formAmount > 0) {
            await processWalletAmount(id, {
                direction,
                amount: formAmount,
                note
            });
            await refresh();
            setDirection(undefined);
            setFormAmount(undefined);
            setNote('');
            clearModifyMode();
        }
    }

    return (
        <>
            <Sheet
                variant="outlined"
                sx={{ flex: 1 }}
            >
                <Box p={2}>
                    <Typography level="title-md">{t('common.balance')}</Typography>
                    <Typography level="body-sm">{t('wallets.amountDescription')}</Typography>
                </Box>
                <Divider />
                <Stack
                    p={2}
                    flexDirection="column"
                    rowGap={1}
                >
                    <Typography level="h3" alignSelf="flex-end" startDecorator={<EuroRoundedIcon />}>{displayAmount(amount)}</Typography>
                    <Stack
                        flexDirection="row"
                        gap={1}
                    >
                        <Button
                            sx={{ flex: 1 }}
                            variant="solid"
                            startDecorator={<AddCircleOutlineRoundedIcon />}
                            onClick={() => { showModifyMode(TransactionDirection.BookIn); }}
                        >
                            {t('transactions.directions.BookIn')}
                        </Button>
                        <Button
                            sx={{ flex: 1 }}
                            variant="solid"
                            endDecorator={<RemoveCircleOutlineRoundedIcon />}
                            onClick={() => { showModifyMode(TransactionDirection.BookOut); }}
                        >
                            {t('transactions.directions.BookOut')}
                        </Button>
                    </Stack>
                </Stack>
            </Sheet>
            <FormModal
                open={modifyMode}
                onCancel={clearModifyMode}
                onSave={handleSubmit}
            >
                <Typography level="title-lg">{t('transactions.directions.BookIn')} / {t('transactions.directions.BookOut')}</Typography>
                <Divider />
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <RadioInput
                            label={t('common.direction')}
                            options={Object.values(TransactionDirection).map(d => ({ label: t(`transactions.directions.${d}`), value: d }))}
                            RadioGroupProps={{
                                orientation: 'horizontal',
                                value: direction ?? '',
                                onChange: event_ => { handleDirectionChange(event_.target.value); },
                            }}
                            FormControlProps={{
                                required: true
                            }}
                        />
                    </Grid>
                    {bookmarks !== undefined && (
                        <Grid xs={12}>
                            <Select
                                label={t('payments.bookmarks.singular')}
                                options={bookmarkOptions}
                                renderOption={(o, index) => (
                                    <Option key={`bookmark-${  index}`} value={o}>
                                        {displayBookmarkLabel(o)}
                                    </Option>
                                )}
                                SelectProps={{
                                    placeholder: t('hints.select'),
                                    onClear: () => { handleBookmarkSelect(); },
                                    onChange: (_, value) => { handleBookmarkSelect(value ?? undefined); },
                                    renderValue: o =>
                                        o === null
                                            ? ''
                                            : displayBookmarkLabel(o.value)
                                }}
                            />
                        </Grid>
                    )}
                    <Grid xs={12}>
                        <Input
                            label={t('common.amount')}
                            InputProps={{
                                startDecorator: '€',
                                type: 'number',
                                inputMode: 'numeric',
                                value: formAmount ?? '',
                                onChange: event_ => { setFormAmount(event_.target.valueAsNumber); }

                            }}
                            FormControlProps={{
                                required: true
                            }}
                        />
                    </Grid>
                    <Grid xs={12}>
                        <Input
                            label={t('transactions.purpose')}
                            InputProps={{
                                value: note,
                                onChange: event_ => { setNote(event_.target.value); }
                            }}
                            FormControlProps={{
                                required: true
                            }}
                        />
                    </Grid>
                </Grid>
            </FormModal>
        </>
    );
}

type WalletTransactionsProperties = Pick<Wallet, 'id'>

function WalletTransactions({id}: WalletTransactionsProperties) {
    const {t} = useLocalization();
    const {listTransactions} = useWalletApi();

    const pagination = usePagination({
        paginationKey: 'inMemory',
        inMemory: true,
        fetch: async (pageable: Pageable<AccountTransaction>) => await listTransactions(id, pageable)
    });

    const [transaction, setTransaction] = useState<AccountTransaction>();

    return (
        <>
            {/* <Sheet */}
            {/*    variant="outlined" */}
            {/* > */}
            {/*    <form onSubmit={handleSubmit}> */}
            {/*        <Box p={2}> */}
            {/*            <Typography level="title-md">{t('table.filterLabel')}</Typography> */}
            {/*            <Typography level="body-sm">{t('transactions.filterDescription')}</Typography> */}
            {/*        </Box> */}
            {/*        <Divider /> */}
            {/*        <Box p={2}> */}

            {/*        </Box> */}
            {/*        <Divider /> */}
            {/*        <Stack */}
            {/*            sx={{ p: 2 }} */}
            {/*            direction={{ xs: 'column-reverse', sm: 'row' }} */}
            {/*            justifyContent={{ xs: 'stretch', sm: 'space-between' }} */}
            {/*            gap={2} */}
            {/*        > */}
            {/*            <Box */}
            {/*                sx={{ */}
            {/*                    display: 'flex', */}
            {/*                    flexDirection: { */}
            {/*                        xs: 'column', */}
            {/*                        sm: 'row' */}
            {/*                    }, */}
            {/*                    gap: 2 */}
            {/*                }} */}
            {/*            > */}
            {/*                */}
            {/*            </Box> */}
            {/*            <Box */}
            {/*                sx={{ */}
            {/*                    display: 'flex', */}
            {/*                    flexDirection: { */}
            {/*                        xs: 'column', */}
            {/*                        sm: 'row' */}
            {/*                    }, */}
            {/*                    gap: 2 */}
            {/*                }} */}
            {/*            > */}
            {/*                <Button */}
            {/*                    type="submit" */}
            {/*                    startDecorator={<FilterAltRoundedIcon />} */}
            {/*                    disabled={pagination.loading} */}
            {/*                > */}
            {/*                    {t('actions.submit')} */}
            {/*                </Button> */}
            {/*                <Button */}
            {/*                    variant="outlined" */}
            {/*                    color="neutral" */}
            {/*                    startDecorator={<RestartAltRoundedIcon />} */}
            {/*                    onClick={handleReset} */}
            {/*                    disabled={pagination.loading} */}
            {/*                > */}
            {/*                    {t('actions.reset')} */}
            {/*                </Button> */}
            {/*            </Box> */}
            {/*        </Stack> */}
            {/*    </form> */}
            {/* </Sheet> */}

            <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <TransactionExportButton
                    {...{ walletId: id }}
                />
            </Stack>

            <Box my={2} />
            <DataTable
                { ...pagination }
                searchDisabled
                headers={[
                    { label: t('common.timestamp'), width: 160 },
                    { label: t('common.type'), width: 120 },
                    { label: t('transactions.purpose'), width: 160 },
                    { label: t('transactions.clerk'), width: 100 },
                    { label: t('clubs.singular'), width: 180 },
                    { label: t('devices.singular'), width: 140 },
                    { label: t('common.amount'), width: 120 },
                ]}
                renderTableRow={at => (
                    <TransactionTableRow
                        key={at.uuid}
                        transaction={at}
                        onClick={() => { setTransaction(at); }}
                    />
                )}
                renderListRow={at => (
                    <TransactionListRow
                        key={at.uuid}
                        transaction={at}
                        onClick={() => { setTransaction(at); }}
                    />
                )}
            />
            {transaction !== undefined && <TransactionModal transaction={transaction} onClose={() => { setTransaction(undefined); }} />}
        </>
    );
}

function TransactionTableRow({transaction: {timestamp, type, clearedAt, note, data, clerkName, clubName, clubId, deviceName}, onClick}: { transaction: AccountTransaction, onClick: () => void }) {
    const {language} = useLocalization();
    const isNegative = type === TransactionType.SubtractCredit || type === TransactionType.ConsumeCredit;
    return (
        <tr style={{cursor: 'pointer'}} onClick={onClick}>
            <td>
                <Typography level="body-xs" gutterBottom>
                    <DateTimeDisplay value={timestamp} locale={language} />
                </Typography>
                <TransactionStatusChip clearedAt={clearedAt}/>
            </td>
            <td>
                <TransactionTypeChip type={type}/>
            </td>
            <td>
                <Typography level="body-xs" gutterBottom>{note}</Typography>
                {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                {notBlank(data.purpose) && <TransactionPurposeChip purpose={data.purpose!}/>}
            </td>
            <td>
                {typeof clerkName === 'string' && <UserLabel label={clerkName} noWrap/>}
            </td>
            <td>
                {notBlank(clubName) && notBlank(clubId) && <ClubLabel label={clubName as string} clubId={clubId as string} noWrap />}
            </td>
            <td>
                {notBlank(deviceName) && (
                    <Typography
                        level="body-xs"
                        startDecorator={<DeveloperBoardRoundedIcon/>}
                    >
                        {deviceName}
                    </Typography>
                )}
            </td>
            <td>
                <ColoredAmount amount={data.amount} negative={isNegative} disabled={!notBlank(clearedAt)}/>
            </td>
        </tr>
    );
}

function TransactionListRow({transaction: {timestamp, type, clearedAt, note, data, clerkName}, onClick}: { transaction: AccountTransaction, onClick: () => void }) {
    const {language} = useLocalization();
    return (
        <List
            size="sm"
            sx={{
                '--ListItem-paddingX': 0,
            }}
        >
            <ListItemButton
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'start',
                    '&:hover': {
                        background: 'none !important'
                    }
                }}
                onClick={onClick}
            >
                <ListItemContent>
                    <Typography level="body-xs">
                        <DateTimeDisplay value={timestamp} locale={language} />
                    </Typography>
                    <Typography fontWeight={600} gutterBottom sx={{ mt: 0.5 }}>{note}</Typography>
                    <TransactionTypeChip type={type}/>
                    {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
                    {notBlank(data.purpose) && <TransactionPurposeChip purpose={data.purpose!}/>}
                    {typeof clerkName === 'string' && <UserLabel label={clerkName} noWrap/>}
                </ListItemContent>
                <TransactionStatusChip clearedAt={clearedAt}/>
            </ListItemButton>
            <ListDivider />
        </List>
    );
}