import {utils, writeFile} from 'xlsx';
import React, {type FormEvent, useState} from 'react';
import IosShareRoundedIcon from '@mui/icons-material/IosShareRounded';
import {Button, Divider, Grid, Option, Typography} from '@mui/joy';
import {type Dayjs} from 'dayjs';
import {useFlag} from '@mlyngvo/common-ui';
import {useLocalization} from '../../context/localization';
import {toDateString, toDatetimeString, notBlank} from '../../utils';
import {useWalletApi} from '../../data/wallet';
import {computeColumnLengths, formatCellAsNumber} from '../../excels';
import {FormModal} from '../../component/form-modal';
import {Select} from '../../component/select';
import {TransactionDirection} from '../../data/statistic';
import {DatePicker} from '../../component/date-picker';

function formatAmount(value?: number) {
    return value?.toFixed(2) ?? '0';
}

export function TransactionExportButton({walletId}: { walletId: string }) {
    const {t} = useLocalization();
    const {find, listTransactions} = useWalletApi();

    const [exportMode, setExportMode, clearExportMode] = useFlag(false);

    /* eslint-disable unicorn/no-null */
    const [from, setFrom] = useState<Dayjs|null>(null);
    const [until, setUntil] = useState<Dayjs|null>(null);
    /* eslint-enable unicorn/no-null */

    const [direction, setDirection] = useState('');

    async function exportToExcel(event_: FormEvent) {
        event_.preventDefault();

        let wallet;
        try {
            wallet = await find(walletId);
        } catch (error) {
            console.error('Failed to fetch wallet', error);
            return;
        }

        if (wallet === undefined) {
            console.error('Empty data return.');
            return;
        }

        const fromString = from?.toISOString();
        const untilString = until?.toISOString();

        const workbook = utils.book_new();

        const worksheet = utils.json_to_sheet([]);
        utils.book_append_sheet(workbook, worksheet, '');

        utils.sheet_add_aoa(worksheet, [[`${t('stats.types.Transactions')} - ${t('wallets.singular')} (${t(`wallets.types.${wallet.type}`)})`]], { origin: 'A1' });
        utils.sheet_add_aoa(worksheet, [[`${t('accounts.singular')}: ${  wallet.accountName}`]], { origin: 'A2' });

        const timeframe: string[] = [];
        /* eslint-disable @typescript-eslint/no-non-null-assertion */
        if (fromString !== null) timeframe.push(toDateString(fromString!));
        if (untilString !== null) timeframe.push(toDateString(untilString!));
        /* eslint-enable @typescript-eslint/no-non-null-assertion */
        utils.sheet_add_aoa(worksheet, [[`${t('common.timeframe')}: ${timeframe.join(' - ')}`]], { origin: 'A3' });

        const headers = [
            t('common.timestamp'),
            t('transactions.clearedAt'),
            t('common.type'),
            t('transactions.purpose'),
            t('clubs.singular'),
            t('devices.singular'),
            'Port #',
            t('common.quantity'),
            t('devices.quantity.Balls'),
            t('transactions.clerk'),
            t('common.amount'),
            t('transactions.prevBalance'),
            t('transactions.newBalance'),
            t('common.note')
        ];

        let page = 0;
        let totalPage = 1;
        try {
            do {
                // eslint-disable-next-line no-await-in-loop
                const data = await listTransactions(walletId, {
                    page,
                    size: 100,
                    sort: {
                        fields: ['timestamp'],
                        order: 'asc'
                    },
                    filter: {
                        from: fromString,
                        until: untilString,
                    }
                });
                if (data === undefined) {
                    console.error('Failed to fetch data.');
                    return;
                }

                const rows: Array<Record<string, string>> = data.content.map(at => ({
                    timestamp: toDatetimeString(at.timestamp),
                    clearedAt: at.clearedAt === undefined ? '' : toDatetimeString(at.clearedAt),
                    type: t(`transactions.types.${at.type}`),
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    purpose: notBlank(at.data.purpose) ? t(`transactions.purposes.${at.data.purpose!}`) : '',
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    club: notBlank(at.clubName) ? at.clubName! : '',
                    device: at.deviceName ?? '',
                    port: at.data.portName ?? '',
                    value: String(at.data.value ?? ''),
                    balls: String(at.data.ballQuantity ?? ''),
                    clerk: at.clerkName ?? '',
                    amountIntl: formatAmount(at.data.amount),
                    previousBalance: formatAmount(at.previousAmount),
                    newBalance: formatAmount(at.newAmount),
                    note: at.note ?? ''
                }));

                utils.sheet_add_json(worksheet, rows, { origin: 'A5' });

                formatCellAsNumber(worksheet, 5, 7, rows.length);
                formatCellAsNumber(worksheet, 5, 8, rows.length);
                formatCellAsNumber(worksheet, 5, 10, rows.length, '0.00');
                formatCellAsNumber(worksheet, 5, 11, rows.length, '0.00');
                formatCellAsNumber(worksheet, 5, 12, rows.length, '0.00');

                worksheet['!cols'] = computeColumnLengths(rows);

                totalPage = data.totalPages;
                page += 1;
            } while (page < totalPage);
        } catch (error) {
            console.error('Failed to get data', error);
        }

        utils.sheet_add_aoa(worksheet, [headers], { origin: 'A5' });

        writeFile(workbook, `${t('wallets.singular')} ${t('wallets.singular')} - ${wallet.accountName} - ${t(`wallets.types.${wallet.type}`)}.xlsx`, { compression: true });
    }

    return (
        <>
            <Button
                variant="outlined"
                startDecorator={<IosShareRoundedIcon />}
                onClick={setExportMode}
            >
                {t('actions.export')}
            </Button>
            <FormModal
                open={exportMode}
                onCancel={clearExportMode}
                onSave={exportToExcel}
                saveLabel={t('actions.export')}
            >
                <Typography level="title-lg">{t('actions.export')}</Typography>
                <Divider />
                <Grid container spacing={2}>
                    <Grid xs={12}>
                        <Select
                            emptyValue
                            label={t('common.direction')}
                            options={Object.values(TransactionDirection)}
                            renderOption={o => (
                                <Option key={o} value={o}>{t(`transactions.directions.${o}`)}</Option>
                            )}
                            SelectProps={{
                                placeholder: t('hints.select'),
                                value: direction as TransactionDirection,
                                onChange: (_, value) => { setDirection(value ?? ''); },
                                onClear: () =>  { setDirection(''); }
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <DatePicker
                            label={t('common.from')}
                            PickerProps={{
                                value: from,
                                onChange: setFrom
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={6}>
                        <DatePicker
                            label={t('common.until')}
                            PickerProps={{
                                value: until,
                                onChange: setUntil
                            }}
                        />
                    </Grid>
                </Grid>
            </FormModal>
        </>
    );
}